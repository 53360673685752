body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eee;
}


@media (min-width: 720px) {
  .modal-dialog {
    max-width: 630px;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.sr-only {
  display: none;
}

.booking-table {
  overflow-x: scroll;
}

.modal {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s ease;
}

.modal.show {
  background-color: rgba(0, 0, 0, 0.5);
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3E%3Cpath stroke=%27%23252f40%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3E%3C/svg%3E");
}

.tableFixHead {
  overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
  height: calc(100vh - 250px); /* gives an initial height of 200px to the table */

  tbody tr td:first-child {
    position: sticky; /* make the table heads sticky */
    left: 0; /* table head will be placed from the top of the table and sticks to it */
    background-color: #fff;
  }
}

.tableFixHead thead {
  position: sticky; /* make the table heads sticky */
  top: 0; /* table head will be placed from the top of the table and sticks to it */
  background: #fff;

  tr th {
    border-bottom: 1px solid #000;
  }
}
